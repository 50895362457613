<template>
  <div class="d-page-wrapper d-error-page">
     <div
      v-html="$t('unauthorizedPage.title')"
      class="d-error-page-title"
    ></div>
    <div
      v-html="$t('unauthorizedPage.message')"
      class="d-error-page-message"
    ></div>
    <v-btn
      v-if="isAuthenticated"
      class="mt-6"
      depressed
      @click="logout"
    >
      {{ $t('logout') }}
    </v-btn>
  </div>
</template>


<script>
import store from '@/store'

import authMixin from '@/mixins/auth.mixin'


export default {
  mixins: [
    authMixin
  ],


  beforeRouteEnter (to, from, next) {
    const user = store.state.user.currentUser

    if (!user || !user.isActive) {
      next()
    } else {
      next(from)
    }
  }
}
</script>
